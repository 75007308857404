<template>
  <footer>
    <q-separator></q-separator>
    <div class="q-px-md q-py-xs flex">
      <q-space></q-space>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="$t('MBO.BACK')"
        class="q-ma-xs"
        @click="onBackClick"
      ></q-btn>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="event.status === Status.published ? $t('MBO.SAVE_MODIFICATION') : $t('MBO.SAVE')"
        class="q-ma-xs"
        color="primary"
        @click="onSaveClick"
      ></q-btn>
    </div>
    <dialog-confirm-component
      ref="dialogConfirm"
      @confirm="saveEvent"
    ></dialog-confirm-component>
    <dialog-preview-component ref="dialogPreview"></dialog-preview-component>
  </footer>
</template>
<script>
  import { defineComponent, computed, ref, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { useI18n } from 'vue-i18n';
  import { Status } from '@/common/constants/statuses';
  import DialogConfirmComponent from '@/components/events/event/components/dialog-confirm.component.vue';
  import DialogPreviewComponent from '@/components/events/event/components/dialog-preview.component.vue';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props) {
      const { state, dispatch, commit } = useStore();
      const router = useRouter();
      const $q = useQuasar();
      const { t } = useI18n();
      const event = computed(() => state[EventsStore.name][EventStore.name].event);
      const dialogPreview = ref('dialogPreview');
      const dialogConfirm = ref('dialogConfirm');
      const $alert = inject('$alert');
      async function saveEvent() {
        try {
          await dispatch(`${EventsStore.name}/${EventStore.name}/saveEvent`, event.value);
          if (event.value.status === Status.draft) {
            dialogPreview.value.open(event.value.id);
          } else {
            await router.push({ name: 'events.search' });
          }
        } catch (error) {
          const { violations } = error.response.data;
          await $alert({
            title: 'Error',
            content: violations.map(({ fieldName, message }) => `<div>${fieldName} - ${message}</div>`),
          });
        }
      }
      return {
        Status,
        event,
        dialogPreview,
        dialogConfirm,
        saveEvent,
        onBackClick() {
          router.push({ name: 'events.search' });
        },
        async onSaveClick() {
          props.validations.event.$touch();
          if (props.validations.event.$error) {
            $q.notify(t('MBO.FILL_REQUIRED_FIELDS'));
            return;
          }
          const { valid, messages } = await dispatch(`${EventsStore.name}/${EventStore.name}/validateCoordinates`, event.value);
          if (valid) {
            const { validationOverrideReason, ...eventModel } = event.value;
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, eventModel);
            await saveEvent();
          } else {
            dialogConfirm.value.open(messages);
          }
        },
      };
    },
    components: {
      DialogConfirmComponent,
      DialogPreviewComponent,
    },
  });
</script>
