<template>
  <em-dialog-layout
    max-width="1000px"
    ref="dialogRef"
    @hide="onDialogHide()"
  >
    <template #activator>
      <slot
        :open="open"
        name="activator"
      ></slot>
    </template>
    <template #title>
      <div class="text-h6">
        <span>{{ $t('MBO.EVENT_PREVIEW') }}</span>
      </div>
      <div class="q-pt-md">
        <span>{{ $t('MBO.PLEASE_VERIFY_ALL_INFORMATION') }}</span>
      </div>
    </template>
    <template #content>
      <div v-html="template"></div>
    </template>
    <template #actions="{ close }">
      <q-space></q-space>
      <q-btn
        :label="$t('MBO.MAKE_CORRECTIONS')"
        flat
        @click="close"
      ></q-btn>
      <q-btn
        :label="$t('MBO.LEAVE_AS_DRAFT')"
        flat
        @click="onLeaveClick()"
      ></q-btn>
      <q-btn
        :label="$t('MBO.PUBLISH')"
        color="primary"
        flat
        @click="onPublishClick()"
      ></q-btn>
    </template>
  </em-dialog-layout>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useDialogPluginComponent } from 'quasar';
  import { useRouter } from 'vue-router';
  import { Status } from '@/common/constants/statuses';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    setup() {
      const { state, dispatch, commit } = useStore();
      const { dialogRef } = useDialogPluginComponent();
      const router = useRouter();
      const event = computed(() => state[EventsStore.name][EventStore.name].event);
      const template = ref(null);
      return {
        dialogRef,
        template,
        async open(payload) {
          template.value = await dispatch(`${EventsStore.name}/${EventStore.name}/getEventTemplate`, payload);
          dialogRef.value.open();
        },
        onLeaveClick() {
          dialogRef.value.close();
          router.push({ name: 'events.search' });
        },
        async onPublishClick() {
          try {
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, {
              ...event.value,
              status: Status.published,
            });
            await dispatch(`${EventsStore.name}/${EventStore.name}/saveEvent`, event.value.saveObj);
          } finally {
            dialogRef.value.close();
            await router.push({ name: 'events.search' });
          }
        },
        onDialogHide() {
          template.value = null;
        },
      };
    },
  });
</script>
