<template>
  <em-dialog-layout
    ref="dialogRef"
    max-width="1000px"
    @hide="onDialogHide()"
  >
    <template #activator>
      <slot
        :open="open"
        name="activator"
      ></slot>
    </template>
    <template #title>
      <div class="text-h6">
        <span>{{ $t('MBO.HISTORY') }}</span>
      </div>
    </template>
    <template #content>
      <em-table
        :columns="HistoryColumns"
        :rows="dialogHistoryOutput"
        row-key="date"
      >
        <template #avatar="{ item }">
          <q-avatar
            :color="ActivityColor[item.type]"
            class="q-mx-xs"
            text-color="white"
            size="md"
          >
            <q-icon
              :name="ActivityIcon[item.type]"
              size="sm"
            >
              <q-tooltip>
                <div>{{ item.type }}</div>
              </q-tooltip>
            </q-icon>
          </q-avatar>
        </template>
      </em-table>
    </template>
    <template #actions="{ close }">
      <q-space></q-space>
      <q-btn
        v-close-popup
        :label="$t('MBO.CLOSE')"
        color="primary"
        flat
        @click="close()"
      ></q-btn>
    </template>
  </em-dialog-layout>
</template>
<script>
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { useDialogPluginComponent } from 'quasar';
  import { HistoryColumns } from '@/components/events/search/search.constant';
  import EventsStore from '@/components/events/events.store';
  import SearchStore from '@/components/events/search/search.store';
  import { ActivityColor, ActivityIcon } from '@/components/dashboard/recent-activities/recent-activities.constant';

  export default defineComponent({
    setup() {
      const { state, dispatch, commit } = useStore();
      const { dialogRef } = useDialogPluginComponent();
      const dialogHistoryOutput = computed(() => state[EventsStore.name][SearchStore.name].dialogHistoryOutput);
      return {
        ActivityColor,
        ActivityIcon,
        HistoryColumns,
        dialogRef,
        dialogHistoryOutput,
        async open({ id }) {
          await dispatch(`${EventsStore.name}/${SearchStore.name}/getEventHistory`, id);
          dialogRef.value.open();
        },
        onDialogHide() {
          commit(`${EventsStore.name}/${SearchStore.name}/setDialogHistoryOutput`);
        },
      };
    },
  });
</script>
