<template>
  <div class="q-gutter-lg">
    <div>
      <div class="row q-col-gutter-sm">
        <div class="col-12 col-sm-4">
          <q-select
            v-model="category"
            :error="v$.event.category.$error"
            :options="Categories"
            :label="$t('MBO.CATEGORY')"
            map-options
            hide-bottom-space
            emit-value
            outlined
            dense
          ></q-select>
        </div>
        <div
          v-if="isCategoryEnvironment || isCategorySecurity"
          class="col-12 col-sm-4"
        >
          <q-select
            v-model="event.riskLevel"
            :error="v$.event.riskLevel.$error"
            :options="RiskLevels"
            :label="$t('MBO.RISK_LEVEL')"
            hide-bottom-space
            outlined
            dense
          ></q-select>
        </div>
      </div>
    </div>
    <div>
      <div class="row q-col-gutter-sm">
        <div class="col-12 col-sm-4">
          <q-select
            v-model="scope"
            :error="v$.event.scope.$error"
            :options="ScopeEvents"
            :label="$t('MBO.SCOPE')"
            map-options
            hide-bottom-space
            emit-value
            outlined
            dense
          ></q-select>
        </div>
        <div
          v-if="isScopeCountry"
          class="col-12 col-sm-4"
        >
          <em-country-select
            v-model="countries"
            :error="v$.event.countries.$error"
            :label="$t('MBO.COUNTRIES')"
            hide-bottom-space
            multiple
          ></em-country-select>
        </div>
        <div
          v-else-if="isScopeRegion"
          class="col-12 col-sm-4"
        >
          <em-region-select
            v-model="event.region"
            :error="v$.event.region.$error"
            :label="$t('MBO.REGION')"
            hide-bottom-space
          ></em-region-select>
        </div>
        <div
          v-if="event.countries.length > 0"
          class="col-12 col-sm-4"
        >
          <q-checkbox
            v-model="tags"
            :label="$t('MBO.COUNTRYWIDE')"
          ></q-checkbox>
        </div>
      </div>
    </div>
    <div v-if="event.countries.length > 0 || event.region">
      <div class="row q-col-gutter-sm">
        <div class="col-12 col-sm-6">
          <q-input
            v-model="event.latitude"
            :error="v$.event.latitude.$error"
            :label="$t('MBO.LATITUDE')"
            :readonly="isCategoryGeopolitics || isCategoryCyber && event.countries.length > 0"
            hide-bottom-space
            outlined
            dense
          >
            <template
              v-if="!(isCategoryGeopolitics || isCategoryCyber && event.countries.length > 0)"
              v-slot:before
            >
              <dialog-map-component>
                <template #activator="{ open }">
                  <q-btn
                    round
                    color="primary"
                    icon="location_on"
                    @click="open(event)"
                  ></q-btn>
                </template>
              </dialog-map-component>
            </template>
          </q-input>
        </div>
        <div class="col-12 col-sm-6">
          <q-input
            v-model="event.longitude"
            :error="v$.event.longitude.$error"
            :label="$t('MBO.LONGITUDE')"
            :readonly="isCategoryGeopolitics || isCategoryCyber && event.countries.length > 0"
            hide-bottom-space
            outlined
            dense
          ></q-input>
        </div>
      </div>
    </div>
    <div>
      <em-input-languages :errors="[v$.event.title, v$.event.content, v$.event.advice]">
        <template #input="{ code }">
          <div class="q-gutter-md">
            <q-input
              v-model="event.title[code]"
              :error="v$.event.title[code] && v$.event.title[code].$error"
              :label="$t(`MBO.TITLE_${code}`)"
              hide-bottom-space
              outlined
              dense
            ></q-input>
            <em-tinymce
              v-model="event.content[code]"
              :error="v$.event.content[code] && v$.event.content[code].$error"
              :options="ToolbarOptions"
            >
              <span>{{ $t(`MBO.CONTENT_${code}`) }}</span>
            </em-tinymce>
            <em-tinymce
              v-model="event.advice[code]"
              :error="v$.event.advice[code] && v$.event.advice[code].$error"
              :options="ToolbarOptions"
            >
              <span>{{ $t(`MBO.ADVICE_${code}`) }}</span>
            </em-tinymce>
          </div>
        </template>
      </em-input-languages>
    </div>
    <div
      v-if="isPublished"
      class="row"
    >
      <q-checkbox
        v-model="event.resend"
        :class="$style.checkbox"
        :label="$t('MBO.REDISTRIBUTE_MODIFIED_EVENT')"
      ></q-checkbox>
    </div>
  </div>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import { Status } from '@/common/constants/statuses';
  import { Category, Categories } from '@/common/constants/categories';
  import { RiskLevels } from '@/common/constants/risk-levels';
  import { ScopeEvent, ScopeEvents } from '@/common/constants/scope-event';
  import { Tag } from '@/common/constants/tags';
  import { ToolbarOptions } from '@/components/events/event/event.constant';
  import DialogMapComponent from '@/components/events/event/components/dialog-map.component.vue';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
      scopeEvent: {
        type: Number,
      },
    },
    setup(props) {
      const { state, dispatch, commit } = useStore();
      const event = computed(() => state[EventsStore.name][EventStore.name].event);
      const isCategoryEnvironment = computed(() => event.value.category === Category.environment);
      const isCategorySecurity = computed(() => event.value.category === Category.security);
      const isCategoryGeopolitics = computed(() => event.value.category === Category.geopolitics);
      const isCategoryCyber = computed(() => event.value.category === Category.cyber);
      const isScopeCountry = computed(() => event.value.scope === ScopeEvent.country);
      const isScopeRegion = computed(() => event.value.scope === ScopeEvent.region);
      const isPublished = computed(() => event.value.status === Status.published);
      const getCountryCapital = async (payload) => {
        const [latitude, longitude] = await dispatch(`${EventsStore.name}/${EventStore.name}/getCountryCapital`, payload);
        return { latitude, longitude };
      };
      return {
        Category,
        Categories,
        RiskLevels,
        ScopeEvents,
        ToolbarOptions,
        v$: ref(props.validations),
        event,
        isCategoryEnvironment,
        isCategorySecurity,
        isCategoryGeopolitics,
        isCategoryCyber,
        isScopeCountry,
        isScopeRegion,
        isPublished,
        category: computed({
          get() {
            return event.value.category;
          },
          async set(payload) {
            const isCategoryEnvironment = payload === Category.environment;
            const isCategorySecurity = payload === Category.security;
            const isCategoryGeopolitics = payload === Category.geopolitics;
            const isCategoryCyber = payload === Category.cyber;
            let latLng = null;
            if ((isCategoryGeopolitics || isCategoryCyber) && event.value.countries.length > 0) {
              latLng = await getCountryCapital(event.value.countries[0]);
            }
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, {
              ...event.value,
              category: payload,
              riskLevel: isCategoryEnvironment || isCategorySecurity ? event.value.riskLevel : null,
              tags: event.value.countries.length > 0 ? event.value.tags : [],
              latitude: latLng ? latLng.latitude : null,
              longitude: latLng ? latLng.longitude : null,
            });
          },
        }),
        scope: computed({
          get() {
            return event.value.scope;
          },
          set(payload) {
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, {
              ...event.value,
              scope: payload,
              countries: [],
              tags: [],
              region: null,
              latitude: null,
              longitude: null,
            });
          },
        }),
        countries: computed({
          get() {
            return event.value.countries;
          },
          async set(payload) {
            const [oldValue] = event.value.countries;
            const [newValue] = payload;
            let latitude = null;
            let longitude = null;
            if (newValue) {
              if (newValue === oldValue) {
                latitude = event.value.latitude;
                longitude = event.value.longitude;
              } else if (isCategoryGeopolitics.value || isCategoryCyber.value) {
                const latLng = await getCountryCapital(newValue);
                latitude = latLng.latitude;
                longitude = latLng.longitude;
              }
            }
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, {
              ...event.value,
              countries: payload,
              latitude,
              longitude,
            });
          },
        }),
        tags: computed({
          get() {
            return event.value.tags.length > 0;
          },
          set(payload) {
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, {
              ...event.value,
              tags: payload ? [Tag.countrywide] : [],
            });
          },
        }),
      };
    },
    components: {
      DialogMapComponent,
    },
  });
</script>
<style lang="scss" module>
  .checkbox {
    margin-left: -8px;
  }
</style>
