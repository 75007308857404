<template>
  <em-dialog-layout
    ref="dialogRef"
    max-width="1000px"
    full-height
    @show="onDialogShow()"
    @hide="onDialogHide()"
  >
    <template #activator>
      <slot
        :open="open"
        name="activator"
      ></slot>
    </template>
    <template #title>
      <div class="text-h6 q-mb-sm">
        <span>{{ $t('MBO.MAP') }}</span>
      </div>
      <div
        v-click-away="onClickAway"
        :class="$style.search"
      >
        <q-input
          v-model="search"
          :loading="searchLoading"
          :label="$t('MBO.SEARCH')"
          outlined
          dense
          bottom-slots
          @keyup.enter="onSearchClick()"
        >
          <template v-slot:hint>
            <span>{{ $t('MBO.SEARCH_HINT') }}</span>
          </template>
          <template v-slot:append>
            <q-btn
              :disable="search.length < MinSearchLength"
              icon="search"
              color="primary"
              flat
              round
              @click="onSearchClick()"
            ></q-btn>
          </template>
        </q-input>
        <q-list
          v-if="addresses"
          :class="$style.search__list"
          class="rounded-borders q-mt-xs"
          dense
          bordered
        >
          <template v-if="addresses.length > 0">
            <q-item
              v-ripple
              v-for="(item, index) in addresses"
              :key="index"
              clickable
              @click="onListItemClick(item)"
            >
              <q-item-section>{{ item.display_name }}</q-item-section>
              <q-item-section avatar>
                <q-img
                  v-if="getIcon(item)"
                  :src="require(`@/assets/map-icons/${getIcon(item)}.p.20.png`)"
                  :class="$style.search__icon"
                  spinner-color="primary"
                ></q-img>
              </q-item-section>
            </q-item>
          </template>
          <q-item v-else>
            <q-item-section>{{ $t('MBO.NO_RESULTS') }}</q-item-section>
          </q-item>
        </q-list>
      </div>
    </template>
    <template #content>
      <div :class="$style.banner">
        <q-banner
          v-if="banner"
          inline-actions
          rounded
          class="bg-negative text-white"
        >
          <span>{{ $t('MBO.MAP_BANNER') }}</span>
          <template v-slot:action>
            <q-btn
              flat
              round
              dense
              icon="block"
              @click="onCloseBannerClick"
            ></q-btn>
          </template>
        </q-banner>
      </div>
      <div
        :class="$style.map"
        ref="mapContainer"
      ></div>
      <q-icon
        :class="$style.pin"
        size="lg"
        name="push_pin"
        color="primary"
      ></q-icon>
    </template>
    <template #actions>
      <q-space></q-space>
      <q-btn
        :label="$t('MBO.SELECT')"
        color="primary"
        flat
        @click="onSelectButtonClick()"
      ></q-btn>
    </template>
  </em-dialog-layout>
  <dialog-map-confirm-component
    ref="dialogMapConfirm"
    @close="close()"
  ></dialog-map-confirm-component>
</template>
<script>
  import { defineComponent, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useDialogPluginComponent } from 'quasar';
  import L from 'leaflet';
  import 'leaflet.gridlayer.googlemutant';
  import { MapOptions, GoogleMutantOptions } from '@/common/constants/map';
  import { MapIcons } from '@/common/constants/map-icons';
  import { ScopeEvent } from '@/common/constants/scope-event';
  import { MinSearchLength } from '@/components/events/event/event.constant';
  import DialogMapConfirmComponent from '@/components/events/event/components/dialog-map-confirm.component.vue';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    setup() {
      const { state, dispatch, commit } = useStore();
      const { dialogRef } = useDialogPluginComponent();
      const dialogMapConfirm = ref('dialogMapConfirm');
      const event = computed(() => state[EventsStore.name][EventStore.name].event);
      const mapContainer = ref(null);
      const search = ref('');
      const searchLoading = ref(false);
      const banner = ref(true);
      let map = null;
      let location = null;
      const closeList = () => {
        commit(`${EventsStore.name}/${EventStore.name}/setAddresses`);
        search.value = '';
      };
      return {
        MinSearchLength,
        dialogRef,
        dialogMapConfirm,
        event,
        mapContainer,
        search,
        searchLoading,
        banner,
        addresses: computed(() => state[EventsStore.name][EventStore.name].addresses),
        onClickAway: closeList,
        async open({ latitude, longitude, countries, region }) {
          if (latitude && longitude) {
            location = [latitude, longitude];
            banner.value = false;
          } else if (countries.length > 0) {
            const [country] = countries;
            location = await dispatch(`${EventsStore.name}/${EventStore.name}/getCountryCapital`, country);
            banner.value = true;
          } else if (region) {
            location = await dispatch(`${EventsStore.name}/${EventStore.name}/getRegionCentroid`, region);
            banner.value = true;
          }
          dialogRef.value.open();
        },
        close() {
          dialogRef.value.close();
        },
        getIcon(payload) {
          return MapIcons[`${payload.class}:${payload.type}`];
        },
        onDialogShow() {
          const options = {
            center: location || MapOptions.center,
            zoom: location ? MapOptions.nearZoom : MapOptions.zoom,
          };
          map = L.map(mapContainer.value).setView(options.center, options.zoom);
          L.gridLayer.googleMutant(GoogleMutantOptions).addTo(map);
        },
        onDialogHide() {
          if (map) {
            map.remove();
          }
        },
        onListItemClick({ lat, lon }) {
          location = [lat, lon];
          map.flyTo(location, MapOptions.nearZoom);
          closeList();
        },
        async onSelectButtonClick() {
          const { lat, lng } = map.getCenter().wrap();
          try {
            const { error, message, areas, scope } = await dispatch(`${EventsStore.name}/${EventStore.name}/checkAreaByPoint`, {
              lat,
              lng,
              scope: event.value.scope,
              areas: event.value.scope === ScopeEvent.country ? event.value.countries : [event.value.region],
            });
            if (error) {
              dialogMapConfirm.value.open({ message, scope, areas });
            } else {
              dialogRef.value.close();
            }
          } finally {
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, {
              ...event.value,
              latitude: lat,
              longitude: lng,
            });
          }
        },
        async onSearchClick() {
          if (search.value.length >= MinSearchLength) {
            try {
              searchLoading.value = true;
              await dispatch(`${EventsStore.name}/${EventStore.name}/getAddresses`, { q: search.value });
            } finally {
              searchLoading.value = false;
            }
          }
        },
        onCloseBannerClick() {
          banner.value = false;
        },
      };
    },
    components: {
      DialogMapConfirmComponent,
    },
  });
</script>
<style lang="scss" module>
  .map {
    margin: -16px;
    flex-grow: 1;

    :global .leaflet-control-zoom {
      margin-left: 16px;
      margin-top: 16px;

      a {
        width: 44px;
        height: 44px;
        line-height: 44px;
        font-size: 26px;
      }
    }
  }
  .search {
    width: 100%;
    position: relative;

    &__list {
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      z-index: 10000;
      background: white;
    }
    &__icon {
      width: 20px;
      height: 20px;
    }
  }
  .banner {
    position: absolute;
    z-index: 10000;
    bottom: 16px;
    left: 16px;
    right: 16px;
  }
  .pin {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
</style>
