<template>
  <em-dialog-layout
    ref="dialogRef"
    max-width="1000px"
    @hide="onDialogHide()"
  >
    <template #activator>
      <slot
        :open="open"
        name="activator"
      ></slot>
    </template>
    <template #title>
      <div class="text-h6">
        <span>{{ $t('MBO.EVENT_PREVIEW') }}</span>
      </div>
    </template>
    <template #content>
      <div v-html="template"></div>
    </template>
    <template #actions="{ close }">
      <q-space></q-space>
      <q-btn
        v-close-popup
        :label="$t('MBO.CLOSE')"
        flat
        color="primary"
        @click="close()"
      ></q-btn>
    </template>
  </em-dialog-layout>
</template>
<script>
  import { defineComponent, ref } from 'vue';
  import { useDialogPluginComponent } from 'quasar';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';
  import { useStore } from 'vuex';

  export default defineComponent({
    setup() {
      const { dispatch } = useStore();
      const { dialogRef } = useDialogPluginComponent();
      const template = ref(null);
      return {
        dialogRef,
        template,
        async open({ id }) {
          template.value = await dispatch(`${EventsStore.name}/${EventStore.name}/getEventTemplate`, id);
          dialogRef.value.open();
        },
        onDialogHide() {
          template.value = null;
        },
      };
    },
  });
</script>
