<template>
  <em-dialog-layout
    max-width="600px"
    ref="dialogRef"
  >
    <template #activator>
      <slot
        :open="open"
        name="activator"
      ></slot>
    </template>
    <template #title>
      <div class="text-h6">
        <q-icon
          name="error_outline"
          size="md"
          class="q-mr-sm"
          color="negative"
        ></q-icon>
        <span class="text-negative">{{ $t('MBO.POSSIBLE_ISSUE') }}</span>
      </div>
    </template>
    <template #content>
      <div class="q-pb-lg">
        <div
          v-for="(item, index) of dialogMessages"
          :key="index"
        >
          <span>{{ item }}</span>
        </div>
      </div>
      <div>
        <q-input
          v-model="event.validationOverrideReason"
          :error="v$.event.validationOverrideReason.$error"
          :label="$t('MBO.REASON')"
          outlined
        ></q-input>
      </div>
    </template>
    <template #actions="{ close }">
      <q-space></q-space>
      <q-btn
        :label="$t('MBO.CANCEL')"
        flat
        @click="close"
      ></q-btn>
      <q-btn
        :label="$t('MBO.CONFIRM')"
        color="primary"
        flat
        @click="onConfirmButtonClick"
      ></q-btn>
    </template>
  </em-dialog-layout>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useDialogPluginComponent, useQuasar } from 'quasar';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    setup(props, { emit }) {
      const { state } = useStore();
      const { dialogRef } = useDialogPluginComponent();
      const $q = useQuasar();
      const v$ = useVuelidate();
      const { t } = useI18n();
      const event = computed(() => state[EventsStore.name][EventStore.name].event);
      const dialogMessages = ref(null);
      return {
        dialogRef,
        v$,
        event,
        dialogMessages,
        open(payload) {
          v$.value.$reset();
          dialogMessages.value = payload;
          dialogRef.value.open();
        },
        async onConfirmButtonClick() {
          v$.value.event.validationOverrideReason.$touch();
          if (v$.value.event.validationOverrideReason.$error) {
            $q.notify(t('MBO.FILL_REQUIRED_FIELDS'));
          } else {
            emit('confirm', event.value);
            dialogRef.value.close();
          }
        },
      };
    },
    validations() {
      return {
        event: {
          validationOverrideReason: { required },
        },
      };
    },
  });
</script>
