<template>
  <div :class="$style.component">
    <div class="q-pa-md overflow-auto">
      <header-component></header-component>
      <form-component :validations="v$"></form-component>
    </div>
    <q-space></q-space>
    <footer-component :validations="v$"></footer-component>
  </div>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import useVuelidate from '@vuelidate/core';
  import { required, requiredIf } from '@vuelidate/validators';
  import { ScopeEvent } from '@/common/constants/scope-event';
  import { Category } from '@/common/constants/categories';
  import { Language } from '@/common/constants/languages';
  import HeaderComponent from '@/components/events/event/components/header.component.vue';
  import FormComponent from '@/components/events/event/components/form.component.vue';
  import FooterComponent from '@/components/events/event/components/footer.component.vue';
  import Store from '@/store';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    async beforeRouteEnter(to, from, next) {
      try {
        const eventId = to.params.id;
        const { previousEvent } = to.query;
        if (eventId) {
          await Store.dispatch(`${EventsStore.name}/${EventStore.name}/getEventById`, eventId);
        } else if (previousEvent) {
          await Store.dispatch(`${EventsStore.name}/${EventStore.name}/getUpdatedEventById`, previousEvent);
        } else {
          Store.commit(`${EventsStore.name}/${EventStore.name}/setEvent`);
        }
        next();
      } catch (error) {
        next({ name: 'events.search' });
        throw error;
      }
    },
    setup() {
      const { state } = useStore();
      const v$ = useVuelidate();
      const event = computed(() => state[EventsStore.name][EventStore.name].event);
      return {
        v$,
        event,
      };
    },
    validations() {
      return {
        event: {
          title: {
            [Language.en]: { required },
            [Language.fr]: { required },
          },
          advice: {
            [Language.en]: { required },
            [Language.fr]: { required },
          },
          content: {
            [Language.en]: { required },
            [Language.fr]: { required },
          },
          category: { required },
          riskLevel: {
            required: requiredIf(function requiredIfSelected() {
              return this.event.category === Category.environment || this.event.category === Category.security;
            }),
          },
          countries: {
            required: requiredIf(function requiredIfSelected() {
              return this.event.scope === ScopeEvent.country;
            }),
          },
          region: {
            required: requiredIf(function requiredIfSelected() {
              return this.event.scope === ScopeEvent.region;
            }),
          },
          latitude: {
            required: requiredIf(function requiredIfSelected() {
              return this.event.scope !== ScopeEvent.world;
            }),
          },
          longitude: {
            required: requiredIf(function requiredIfSelected() {
              return this.event.scope !== ScopeEvent.world;
            }),
          },
          scope: { required },
        },
      };
    },
    components: {
      HeaderComponent,
      FormComponent,
      FooterComponent,
    },
  });
</script>
<style lang="scss" module>
  @import '~@/styles/common/item-component';

  .component {
    @include item-component();
  }
</style>
