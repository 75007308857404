<template>
  <em-table
    v-model:pagination="searchOutput.pagination"
    :fetch="getEvents"
    :columns="SearchColumns"
    :rows="searchOutput.content"
    :pagination="searchOutput.pagination"
    :title="$t('MBO.EVENTS')"
  >
    <template #top>
      <div
        :class="{ 'no-wrap': $q.screen.gt.xs }"
        class="full-width q-col-gutter-sm"
      >
        <div class="row q-col-gutter-sm">
          <div class="col-12 col-md-3 col-sm-6">
            <q-select
              v-model="searchInput.category"
              :options="Categories"
              :label="$t('MBO.CATEGORY')"
              map-options
              hide-bottom-space
              emit-value
              outlined
              dense
              clearable
            ></q-select>
          </div>
          <div class="col-12 col-md-3 col-sm-6">
            <q-input
              v-model="searchInput.title"
              :label="$t('MBO.TITLE')"
              hide-bottom-space
              outlined
              dense
            ></q-input>
          </div>
          <div class="col-12 col-md-6">
            <q-input
              v-model="searchInput.content"
              :label="$t('MBO.CONTENT')"
              hide-bottom-space
              outlined
              dense
            ></q-input>
          </div>
        </div>
        <div class="row q-col-gutter-sm">
          <div class="col-12 col-md-3 col-sm-6">
            <em-input-date
              v-model="searchInput.createdFrom"
              :label="$t('MBO.CREATED_FROM')"
              :max="searchInput.createdTo"
              hide-bottom-space
              dense
              clearable
            ></em-input-date>
          </div>
          <div class="col-12 col-md-3 col-sm-6">
            <em-input-date
              v-model="searchInput.createdTo"
              :label="$t('MBO.CREATED_TO')"
              :min="searchInput.createdFrom"
              hide-bottom-space
              dense
              clearable
            ></em-input-date>
          </div>
          <div class="col-12 col-md-3 col-sm-6">
            <q-select
              v-model="scope"
              :options="ScopeEvents"
              :label="$t('MBO.SCOPE')"
              map-options
              hide-bottom-space
              emit-value
              outlined
              dense
              clearable
            ></q-select>
          </div>
          <div
            v-if="searchInput.scope"
            class="col-12 col-md-3 col-sm-6"
          >
            <em-country-select
              v-if="isScopeCountry"
              v-model="searchInput.country"
              :label="$t('MBO.COUNTRY')"
              hide-bottom-space
              clearable
            ></em-country-select>
            <em-region-select
              v-else-if="isScopeRegion"
              v-model="searchInput.region"
              :label="$t('MBO.REGION')"
              hide-bottom-space
            ></em-region-select>
          </div>
        </div>
        <div
          v-if="isAdvancedSearch"
          class="row q-col-gutter-sm"
        >
          <div class="col-12 col-md-4">
            <q-select
              v-model="searchInput.riskLevel"
              :options="RiskLevels"
              :label="$t('MBO.RISK_LEVELS')"
              hide-bottom-space
              outlined
              dense
              clearable
            ></q-select>
          </div>
          <div class="col-12 col-md-4">
            <q-select
              v-model="searchInput.status"
              :options="Statuses"
              :label="$t('MBO.STATUS')"
              map-options
              hide-bottom-space
              emit-value
              outlined
              dense
              clearable
            ></q-select>
          </div>
          <div class="col-12 col-md-4">
            <q-input
              v-model="searchInput.author"
              :label="$t('MBO.AUTHOR')"
              hide-bottom-space
              outlined
              dense
            ></q-input>
          </div>
        </div>
        <div class="row q-col-gutter-sm">
          <div
            :class="{ 'q-py-xs': $q.screen.lt.sm }"
            class="flex col-12"
          >
            <q-space></q-space>
            <q-btn
              :class="['q-ma-xs', { 'full-width': $q.screen.lt.sm }]"
              text
              @click="onAdvancedSearchClick"
            >
              <span v-if="isAdvancedSearch">{{ $t('MBO.HIDE_ADVANCED_SEARCH') }}</span>
              <span v-else>{{ $t('MBO.SHOW_ADVANCED_SEARCH') }}</span>
            </q-btn>
            <q-btn
              :class="['q-ma-xs', { 'full-width': $q.screen.lt.sm }]"
              color="primary"
              @click="onSearchClick"
            >
              <span>{{ $t('MBO.SEARCH') }}</span>
            </q-btn>
          </div>
        </div>
      </div>
    </template>
    <template #avatar="{ item }">
      <em-category-label
        :category="item.category"
        :riskLevel="item.riskLevel"
      ></em-category-label>
    </template>
    <template #scope="{ item }">
      <em-country-label
        v-if="item.scope === ScopeEvent.country"
        :country-names="item.countryNames"
      ></em-country-label>
      <em-region-label
        v-else-if="item.scope === ScopeEvent.region"
        :region-name="item.regionName"
      ></em-region-label>
      <em-world-label v-else></em-world-label>
    </template>
    <template #actions="{ item }">
      <dialog-preview-component>
        <template #activator="{ open }">
          <q-btn
            v-if="hasFeature(Feature.templateRender)"
            dense
            flat
            round
            icon="preview"
            @click="open(item)"
          >
            <q-tooltip>
              <span>{{ $t('MBO.PREVIEW') }}</span>
            </q-tooltip>
          </q-btn>
        </template>
      </dialog-preview-component>
      <dialog-history-component>
        <template #activator="{ open }">
          <q-btn
            dense
            flat
            round
            icon="manage_search"
            @click="open(item)"
          >
            <q-tooltip>
              <span>{{ $t('MBO.HISTORY') }}</span>
            </q-tooltip>
          </q-btn>
        </template>
      </dialog-history-component>
      <q-btn
        v-if="hasFeature(Feature.eventsWrite) && item.status === Status.published"
        dense
        flat
        round
        icon="upgrade"
        @click="onUpdateClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.UPDATE') }}</span>
        </q-tooltip>
      </q-btn>
      <q-btn
        v-if="hasFeature(Feature.eventsWrite)"
        dense
        flat
        round
        icon="create"
        @click="onEditClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.MODIFY') }}</span>
        </q-tooltip>
      </q-btn>
    </template>
  </em-table>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { hasFeature } from '@/store/helpers/access.helper';
  import { Feature } from '@/common/constants/security';
  import { SearchColumns } from '@/components/events/search/search.constant';
  import { Categories } from '@/common/constants/categories';
  import { RiskLevels } from '@/common/constants/risk-levels';
  import { Status, Statuses } from '@/common/constants/statuses';
  import { ScopeEvent, ScopeEvents } from '@/common/constants/scope-event';
  import DialogPreviewComponent from '@/components/events/search/components/dialog-preview.component.vue';
  import DialogHistoryComponent from '@/components/events/search/components/dialog-history.component.vue';
  import DictionariesStore from '@/store/modules/dictionaries.store';
  import EventsStore from '@/components/events/events.store';
  import SearchStore from '@/components/events/search/search.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    setup() {
      const { state, dispatch, commit } = useStore();
      const router = useRouter();
      const countries = computed(() => state[`${DictionariesStore.name}`].countries);
      const searchInput = computed(() => state[EventsStore.name][SearchStore.name].searchInput);
      const searchOutput = computed(() => state[EventsStore.name][SearchStore.name].searchOutput);
      const isAdvancedSearch = ref(false);

      function getEvents(payload) {
        commit(`${EventsStore.name}/${SearchStore.name}/setSearchInput`, {
          ...searchInput.value,
          ...payload,
        });
        dispatch(`${EventsStore.name}/${SearchStore.name}/getEvents`, searchInput.value.searchObj);
      }
      return {
        hasFeature,
        Feature,
        ScopeEvent,
        Categories,
        Status,
        Statuses,
        RiskLevels,
        SearchColumns,
        ScopeEvents,
        countries,
        searchInput,
        searchOutput,
        isAdvancedSearch,
        isScopeCountry: computed(() => searchInput.value.scope === ScopeEvent.country),
        isScopeRegion: computed(() => searchInput.value.scope === ScopeEvent.region),
        getEvents,
        scope: computed({
          get() {
            return searchInput.value.scope;
          },
          set(payload) {
            commit(`${EventsStore.name}/${SearchStore.name}/setSearchInput`, {
              ...searchInput.value,
              scope: payload,
              country: null,
              region: null,
            });
          },
        }),
        onSearchClick() {
          getEvents({ page: null });
        },
        onAdvancedSearchClick() {
          isAdvancedSearch.value = !isAdvancedSearch.value;
        },
        onUpdateClick({ id, ...event }) {
          commit(`${EventsStore.name}/${EventStore.name}/setEvent`, event);
          router.push({ name: 'events.event', query: { previousEvent: id } });
        },
        onEditClick(payload) {
          commit(`${EventsStore.name}/${EventStore.name}/setEvent`, payload);
          router.push({ name: 'events.event', params: { id: payload.id } });
        },
      };
    },
    components: {
      DialogPreviewComponent,
      DialogHistoryComponent,
    },
  });
</script>
