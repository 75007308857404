<template>
  <router-view></router-view>
</template>
<script>
  import { defineComponent, onUnmounted } from 'vue';
  import Store from '@/store';
  import EventsStore from '@/components/events/events.store';

  export default defineComponent({
    beforeRouteEnter(to, from, next) {
      Store.registerModule(EventsStore.name, EventsStore.module);
      next();
    },
    setup() {
      onUnmounted(() => {
        Store.unregisterModule(EventsStore.name);
      });
    },
  });
</script>
