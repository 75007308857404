<template>
  <header class="text-h6 q-pb-md text-center">
    <span v-if="event.id">
      <span v-if="isPublished">{{ $t('MBO.MODIFY_PUBLISHED_EVENT') }}</span>
      <span v-else>{{ $t('MBO.MODIFY_DRAFT_EVENT') }}</span>
    </span>
    <span v-else-if="isUpdated">{{ $t('MBO.UPDATE_PUBLISHED_EVENT') }}</span>
    <span v-else>{{ $t('MBO.ADD_NEW_EVENT') }}</span>
  </header>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { Status } from '@/common/constants/statuses';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    setup() {
      const { state } = useStore();
      const route = useRoute();
      const event = computed(() => state[EventsStore.name][EventStore.name].event);
      return {
        event,
        isUpdated: computed(() => route.query.previousEvent),
        isPublished: computed(() => event.value.status === Status.published),
      };
    },
  });
</script>
