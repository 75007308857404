<template>
  <em-dialog-layout
    max-width="600px"
    ref="dialogRef"
    @hide="onDialogHide()"
  >
    <template #activator>
      <slot
        :open="open"
        name="activator"
      ></slot>
    </template>
    <template #title>
      <div class="text-h6">
        <q-icon
          name="error_outline"
          size="md"
          class="q-mr-sm"
          color="negative"
        ></q-icon>
        <span class="text-negative">{{ $t('MBO.POSSIBLE_ISSUE_INCIDENT_LOCATION') }}</span>
      </div>
    </template>
    <template #content>
      <div>{{ message }}</div>
      <em-region-select
        v-if="scope === ScopeEvent.region && areas.length > 0"
        v-model="region"
        :region-options="areas"
        :error="v$.region.$error"
        :label="$t('MBO.REGION')"
        class="q-mt-md"
        hide-bottom-space
      ></em-region-select>
    </template>
    <template #actions="{ close }">
      <q-space></q-space>
      <q-btn
        :label="$t('MBO.MAKE_CORRECTIONS')"
        flat
        @click="close"
      ></q-btn>
      <q-btn
        :label="$t('MBO.LEAVE_AS_IT_IS')"
        flat
        @click="onLeaveClick()"
      ></q-btn>
      <q-btn
        :label="$t('MBO.CONFIRM')"
        color="primary"
        flat
        @click="onConfirmClick()"
      ></q-btn>
    </template>
  </em-dialog-layout>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useDialogPluginComponent, useQuasar } from 'quasar';
  import { requiredIf } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';
  import { useI18n } from 'vue-i18n';
  import { ScopeEvent } from '@/common/constants/scope-event';
  import EventsStore from '@/components/events/events.store';
  import EventStore from '@/components/events/event/event.store';

  export default defineComponent({
    emit: ['close'],
    setup(props, { emit }) {
      const { state, commit } = useStore();
      const { dialogRef } = useDialogPluginComponent();
      const $q = useQuasar();
      const v$ = useVuelidate();
      const { t } = useI18n();
      const event = computed(() => state[EventsStore.name][EventStore.name].event);
      const message = ref(null);
      const scope = ref(null);
      const region = ref(null);
      const areas = ref([]);
      return {
        ScopeEvent,
        dialogRef,
        v$,
        event,
        message,
        scope,
        region,
        areas,
        open(payload) {
          message.value = payload.message;
          scope.value = payload.scope;
          areas.value = payload.areas;
          v$.value.$reset();
          dialogRef.value.open();
        },
        onLeaveClick() {
          dialogRef.value.close();
          emit('close');
        },
        async onConfirmClick() {
          v$.value.region.$touch();
          if (v$.value.region.$error) {
            $q.notify(t('MBO.FILL_REQUIRED_FIELDS'));
            return;
          }
          if (scope.value === ScopeEvent.country) {
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, {
              ...event.value,
              countries: areas.value,
            });
          } else {
            commit(`${EventsStore.name}/${EventStore.name}/setEvent`, {
              ...event.value,
              region: region.value,
            });
          }
          dialogRef.value.close();
          emit('close');
        },
        onDialogHide() {
          message.value = null;
          scope.value = null;
          region.value = null;
          areas.value = [];
        },
      };
    },
    validations() {
      return {
        region: {
          required: requiredIf(() => this.scope === ScopeEvent.region && this.areas.length > 0),
        },
      };
    },
  });
</script>
